import React from 'react'
import { Link } from 'react-router-dom';
import project01 from '../img/work/project01.jpg';
import project1 from '../img/work/project1.jpg'
import project2 from '../img/work/project2.jpg';
import project3 from '../img/work/project3.jpg';
import project4 from '../img/work/project4.jpg';
import project6 from '../img/work/project6.jpg';
import project10 from '../img/work/project10.jpg';
import project11 from '../img/work/project11.jpg';
import project12 from '../img/work/triple.jpg';

const Portfolio = () => {
	return (
		<div className="pwork1">
			<div className="portfolio">
				<div className="container">
					<div className="row">
						<div className="col-12">
							<h1>
								my <span>portfolio</span>
							</h1>
							<span class="work">works</span>
						</div>
					</div>
				</div>
			</div>
			<div className="workproject">
				<div className="container">
					<div className="row">
						<div className="col-lg-4 col-md-12">
							<div className="project1">
								<img src={project01} alt="project_Image" />
								<div class="overlay">
									<Link to="https://fitness.stocksollution.in/" target="_blank">
										{' '}
										<div class="text">Health Fitness</div>
									</Link>
								</div>
							</div>
						</div>
						<div className="col-lg-4 col-md-12">
							<div className="project1">
								<img src={project12} alt="project_Image" />
								<div class="overlay">
									<Link to="https://tripleaelevators.com/" target="_blank">
										{' '}
										<div class="text">Triple A Elevator</div>
									</Link>
								</div>
							</div>
						</div>
						<div className="col-lg-4 col-md-12">
							<div className="project1">
								<img src={project1} alt="project_Image" />
								<div class="overlay">
									<Link to="https://kprandassociates.in/" target="_blank">
										<div class="text">KPR & Associates </div>
									</Link>
								</div>
								{/* <div className="hover-box">
									<span className="hover-text">
										<a href="http://drsumeetsharma.com/" class="project-link">
											Dr Sumeet Sharma
										</a>
									</span>
								</div> */}
							</div>
						</div>
						<div className="col-lg-4 col-md-12">
							<div className="project1">
								<img src={project2} alt="project_Image" />
								<div class="overlay">
									<Link to="https://stocksollution.in/" target="_blank">
										{' '}
										<div class="text">Stock Sollution</div>
									</Link>
								</div>
							</div>
						</div>
						<div className="col-lg-4 col-md-12">
							<div className="project1">
								<img src={project3} alt="project_Image" />
								<div class="overlay">
									<Link to="http://vlntechnologies.com/" target="_blank">
										{' '}
										<div class="text">VUDUMULA TECHNOLOGIES LLP</div>
									</Link>
								</div>
							</div>
						</div>
						<div className="col-lg-4 col-md-12">
							<div className="project1">
								<img src={project4} alt="project_Image" />
								<div class="overlay">
									<Link to="https://samathvamindia.com/" target="_blank">
										<div class="text">Samathvam</div>
									</Link>
								</div>
							</div>
						</div>
						<div className="col-lg-4 col-md-12">
							<div className="project1">
								<img src={project10} alt="project_Image" />
								<div class="overlay">
									<Link to="https://utopialearning.co.in/" target="_blank">
										<div class="text">Utopia Learning (Dr.Sumeet Sharma)</div>
									</Link>
								</div>
							</div>
						</div>

						{/* <div className="col-lg-4 col-md-12">
							<div className="project1">
								<img src={project5} alt="project_Image" />
								<div class="overlay">
									<div class="text">Hello World</div>
								</div>
							</div>
						</div> */}
						<div className="col-lg-4 col-md-12">
							<div className="project1">
								<img src={project6} alt="project_Image" />
								<div class="overlay">
									<Link to="http://drsumeetsharma.com/" target="_blank">
										<div class="text">Dr.Sumeet Sharma</div>
									</Link>
								</div>
							</div>
						</div>
						<div className="col-lg-4 col-md-12">
							<div className="project1">
								<img src={project11} alt="project_Image" />
								<div class="overlay">
									<Link to="https://researchleadsinc.com/" target="_blank">
										<div class="text">Research Leads Inc</div>
									</Link>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Portfolio
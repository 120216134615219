import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Navbar from './componets/Navbar';
import About from './pages/About';
import Home from './pages/Home';
import Contact from './pages/Contact'
import Portfolio from './pages/Portfolio';

function App() {
  return (
		// <div className="App">
		//   <header className="App-header">
		//     <img src={logo} className="App-logo" alt="logo" />
		//     <p>
		//       Edit <code>src/App.js</code> and save to reload.
		//     </p>
		//     <a
		//       className="App-link"
		//       href="https://reactjs.org"
		//       target="_blank"
		//       rel="noopener noreferrer"
		//     >
		//       Learn React
		//     </a>
		//   </header>
		// </div>
		<>
			{/* <Home /> */}
			<BrowserRouter>
				<Routes>
					<Route path="/" element={<Navbar />}>
						<Route path="/" element={<Home />} />
						<Route path="About" element={<About />} />
						<Route path="Portfolio" element={<Portfolio />} />
						<Route path="Contact" element={<Contact />} />
					</Route>
				</Routes>
			</BrowserRouter>
		</>
	);
}

export default App;
